<template>
<div>
    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas </span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">NÚMERO TOTAL DE INCIDENCIAS REGISTRADAS PARA LOS PROVEEDORES:
                    </span>
                    <hr>
                    <b-row class="content-justify-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i style="inline-size: auto" class=" ico fas fa-exclamation-circle fa-3x pb-1"></i>
                            <br>
                            <span class="lg-numero">{{listaIncidencias.length}}</span>
                            <br>
                            <span class="text-muted">Incidencias registradas</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i><span class="h5"> Gestión de incidencias de proveedores</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="text-center justify-content-center">
                        <b-col cols="6" class="my-2">
                            <b-button block size="md" variant="custom" :to="{name: 'Proveedores'}">
                                <i style="inline-size: auto" class="fas fa-arrow-left fa-3x pb-1"></i><br><span>Volver</span>
                            </b-button>
                        </b-col>
                        <b-col v-if="checkPermissions('009-REC-PRO-INC','c')==1" cols="6" class="my-2">
                            <b-button block size="md" variant="custom" @click="modalRegistrarIncidencia = true">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br>Nueva incidencia
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i><span class="h5"> Incidencias de Proveedores registradas </span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col md="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="7">
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table hover bordered show-empty mediun responsive outlined :items="listaIncidencias" :fields="camposIncidencias" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template v-slot:cell(opciones)="param">
                                    <b-button v-if="checkPermissions('009-REC-PRO-INC','u')==1" size="sm" v-c-tooltip.hover.click="'Ver detalles'" @click="abrirModalActualizarIndicencia(param)" variant="dark" class=" mr-1 mb-1">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                    <b-button v-if="checkPermissions('009-REC-PRO-INC','d')==1" size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarIncidencia(param)" variant="danger">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination size="sm" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number align="right" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>
    <CModal :show.sync="modalRegistrarIncidencia" :closeOnBackdrop="false" size="lg" color="primario">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nueva incidencia </span>
            </h6>
            <CButtonClose @click="modalRegistrarIncidencia = false" class="text-white" />
        </template>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarIncidencia)">
                <b-row>
                    <b-col lg="12">
                        <validation-provider name="proveedor" rules="required" v-slot="{errors}">
                            <b-form-group label="Proveedor:" class="mb-2">
                                <v-select :reduce="comboProveedor =>comboProveedor.idProveedor" label="razonSocial" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosIncidencia.proveedor , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosIncidencia.proveedor" :options="comboProveedor" @search:blur="blurProveedor">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="6">
                        <validation-provider name="fecha" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha:" class="mb-2">
                                <b-form-input size="md" type="date" :state="getValidationState(validationContext)" placeholder="Ingrese la fecha" v-model="datosIncidencia.fecha"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="fecha real de resolución" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha real de resolución:" class="mb-2">
                                <b-form-input size="md" type="date" :state="getValidationState(validationContext)" placeholder="Ingrese la fecha real" v-model="datosIncidencia.fechaResolucion"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="descripcion:" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Descripción:" class="mb-2">
                                <b-form-textarea v-model="datosIncidencia.descripcion" placeholder="Ingrese la descripción" rows="3" max-rows="6" size="md" :state="getValidationState(validationContext)"></b-form-textarea>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="12">
                        <validation-provider name="accion correctivas:" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Acción correctivas:" class="mb-2">
                                <b-form-textarea v-model="datosIncidencia.accionCorrectivas" placeholder="Ingrese las acciones correctivas" rows="3" max-rows="6" size="md" :state="getValidationState(validationContext)"></b-form-textarea>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="12" class="text-right my-2">
                        <b-button size="md" variant="success" class="mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalRegistrarIncidencia = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>

            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :show.sync="modalActualizarIncidencia" :closeOnBackdrop="false" size="lg" color="primario">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar incidencia</span></h6>
            <CButtonClose @click="modalActualizarIncidencia = false" class="text-white" />
        </template>
        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarIncidencia)">
                <b-row>
                    <b-col lg="12">
                        <validation-provider name="proveedor" rules="required" v-slot="{errors}">
                            <b-form-group label="Proveedor:" class="mb-2">
                                <v-select :reduce="comboProveedor =>comboProveedor.idProveedor" label="razonSocial" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarIncidencia.proveedor , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarIncidencia.proveedor" :options="comboProveedor" @search:blur="blurActualizarProveedor">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="6">
                        <validation-provider name="fecha" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha:" class="mb-2">
                                <b-form-input size="md" type="date" :state="getValidationState(validationContext)" placeholder="Ingrese la fecha" v-model="datosActualizarIncidencia.fecha"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="fecha real de resolución" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha real de resolución:" class="mb-2">
                                <b-form-input size="md" type="date" :state="getValidationState(validationContext)" placeholder="Ingrese la fecha real" v-model="datosActualizarIncidencia.fechaResolucion"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="descripcion:" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Descripción:" class="mb-2">
                                <b-form-textarea v-model="datosActualizarIncidencia.descripcion" placeholder="Ingrese la descripción" rows="3" max-rows="6" size="md" :state="getValidationState(validationContext)"></b-form-textarea>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="12">
                        <validation-provider name="accion correctivas:" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Acción correctivas:" class="mb-2">
                                <b-form-textarea v-model="datosActualizarIncidencia.accionCorrectivas" placeholder="Ingrese las acciones" rows="3" max-rows="6" size="md" :state="getValidationState(validationContext)"></b-form-textarea>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="12" class="text-right my-2">
                        <b-button size="md" variant="success" class="mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalActualizarIncidencia = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>

            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";
export default {
    data() {
        return {
            listaIncidencias: [],
            camposIncidencias: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "razonSocial",
                    label: "Proveedor",
                    class: "text-center"
                },
                {
                    key: "fecha",
                    label: "Fecha de incidencia",
                    class: "text-center"
                },
                {
                    key: "fechaRealResolucion",
                    label: "Fecha de Resolución",
                    class: "text-center"
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center"
                }
            ],

            datosIncidencia: {
                idCliente: '',
                fecha: moment().format('YYYY-MM-DD'),
                proveedor: null,
                fechaResolucion: moment().format('YYYY-MM-DD'),
                accionCorrectivas: '',
                descripcion: ''
            },

            datosActualizarIncidencia: {
                idIncidenciaProveedor: '',
                idCliente: '',
                fecha: '',
                proveedor: null,
                descripcion: '',
                fechaResolucion: '',
                accionCorrectivas: ''
            },

            comboProveedor: [{
                idProveedor: 1,
                descripcion: 'enel',
            }, {
                idProveedor: 2,
                descripcion: 'sedapal',
            }, {
                idProveedor: 3,
                descripcion: 'improvement',
            }, ],

            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            filter: null,
            filterOn: [],

            modalRegistrarIncidencia: false,
            modalActualizarIncidencia: false,

        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        registrarIncidencia() {
            let me = this;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-incidencia-proveedor",
                    me.datosIncidencia, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    me.modalRegistrarIncidencia = false
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.listarIncidencias();
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        actualizarIncidencia() {
            let me = this;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-incidencia-proveedor",
                    me.datosActualizarIncidencia, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    me.modalActualizarIncidencia = false
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.listarIncidencias();
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        abrirModalActualizarIndicencia(param) {
            this.datosActualizarIncidencia.idIncidenciaProveedor = param.item.idIncidenciaProveedor;
            this.datosActualizarIncidencia.fecha = param.item.fecha;
            this.datosActualizarIncidencia.proveedor = param.item.idProveedor;
            this.datosActualizarIncidencia.fechaResolucion = param.item.fechaRealResolucion;
            this.datosActualizarIncidencia.descripcion = param.item.descripcion;
            this.datosActualizarIncidencia.accionCorrectivas = param.item.accionCorrectiva;
            this.modalActualizarIncidencia = true
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },

        listarIncidencias() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-incidencias-proveedores", {
                        params: {
                            idCliente: me.datosIncidencia.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.listaIncidencias = response.data;
                    me.filasTotales = response.data.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarProveedores() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-proveedores", {
                        params: {
                            idCliente: me.datosIncidencia.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboProveedor = response.data
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        eliminarIncidencia(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar la incidencia?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    let me = this;
                    axios
                        .post(
                            CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-incidencia-proveedor", {
                                idIncidenciaProveedor: param.item.idIncidenciaProveedor
                            }, {
                                headers: {
                                    Authorization: `Bearer ${localStorage.token}`
                                },
                            }
                        )
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarIncidencias();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },

        resetModalRegistrarIncidencia() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosIncidencia.fecha = moment().format('YYYY-MM-DD'),
                this.datosIncidencia.proveedor = null;
            this.datosIncidencia.descripcion = '';
            this.datosIncidencia.fechaResolucion = moment().format('YYYY-MM-DD'),
                this.datosIncidencia.accionCorrectivas = '';
        },
        resetModalActualizarIncidencia() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
        },

        blurProveedor() {
            this.computedForm.refs.proveedor.validate();
        },
        blurActualizarProveedor() {
            this.computedActualizarForm.refs.proveedor.validate();
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
        computedActualizarForm() {
            return this.$refs.observer2;
        }
    },
    watch: {
        modalRegistrarIncidencia: function (val) {
            if (val == false) {
                this.resetModalRegistrarIncidencia();
            }
        },
        modalActualizarIncidencia: function (val) {
            if (val == false) {
                this.resetModalActualizarIncidencia();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosIncidencia.idCliente = this.datosActualizarIncidencia.idCliente = user.uidClient;
            this.listarIncidencias();
            this.listarProveedores();
        }
    }

}
</script>
